<mat-form-field>
  <mat-label
    [attr.data-testid]="testIdArea + '-' + testIdControl + '-label'"
    [translate]="labelTranslate"
    [translateParams]="translateDefaultValue(labelTranslateParam)"
  ></mat-label>
  <mat-select
    [attr.data-testid]="testIdArea + '-' + testIdControl + '-select'"
    [formControl]="control"
    [multiple]="true"
    [required]="required"
    [compareWith]="compareWith"
    (selectionChange)="onSelectionChange()"
  >
    <mat-select-trigger>
      <ng-container *ngFor="let option of control.value">
        {{ option[optionKey] + ',' }}
      </ng-container>
    </mat-select-trigger>
    <mat-option
      *ngIf="dropdownOptions.length > 6"
      [attr.data-testid]="testIdArea + '-' + testIdControl + '-search-option'"
    >
      <ngx-mat-select-search
        [noEntriesFoundLabel]="null"
        placeholderLabel="Search"
        [formControl]="optionFilterCtrl"
      ></ngx-mat-select-search>
    </mat-option>
    <div
      *ngIf="emptyOptionList()"
      class="mat-select-search-no-entries-found"
      >No matching results</div
    >
    <ng-container *ngIf="filteredOptions | async as filteredOptionsList">
      <mat-option
        [attr.data-testid]="testIdArea + '-' + testIdControl + '-option-' + filteredOption[optionKey]"
        *ngFor="let filteredOption of filteredOptionsList; trackBy: trackByFn"
        [value]="filteredOption"
        [class.mat-select-hide-options]="hideOptions(filteredOption[optionKey])"
      >
        <ng-container
          [ngTemplateOutlet]="optionTemplate"
          [ngTemplateOutletContext]="{ $implicit: filteredOption }"
        ></ng-container>
      </mat-option>
    </ng-container>
  </mat-select>
  <button
    [appButtonClearOption]="control"
    aria-label="Clear"
    [attr.data-testid]="testIdArea + '-' + testIdControl + '-clear-button'"
    mat-icon-button
    matSuffix
    type="button"
  >
    <mat-icon>close</mat-icon>
  </button>
  <mat-error>
    <app-validation-message [field]="control"></app-validation-message>
  </mat-error>
</mat-form-field>
