<div class="modal-header bulkupload-modal">
  <h4
    class="modal-title"
    id="modal-basic-title"
    >{{ this.form.get('name')?.value }}</h4
  >
  <button
    mat-icon-button
    data-testid="change-status-modal-close-button"
    (click)="close()"
    aria-label="Close"
    type="button"
  >
    <mat-icon class="close-icon">close</mat-icon>
  </button>
</div>
<div
  class="modal-body"
  [formGroup]="form"
>
  <h5>Change Status</h5>
  <mat-radio-group
    class="d-flex flex-column mb-5"
    data-testid="change-status-modal-radio-group"
    formControlName="status"
    required
  >
    <ng-container *ngIf="entityStatusDataSourceService.dataSource$ | async as entityStatuses">
      <mat-radio-button
        [attr.data-testid]="'change-status-modal-status-option-' + status.name"
        [checked]="status.id === form.get('statusID')?.value"
        [title]="status.name"
        [value]="status"
        *ngFor="let status of entityStatuses"
      >
        <app-list-item
          class="border-0"
          [title]="status.name"
          [attr.data-testid]="'change-status-modal-status-option-tail-' + status.name"
        >
        </app-list-item>
      </mat-radio-button>
    </ng-container>
  </mat-radio-group>
  <app-cr-details
    [domain]="data.referencedTableName"
    formControlName="crDetails"
  ></app-cr-details>
  <span
    *ngIf="isWarningVisible"
    class="information-box"
  >
    <mat-icon>warning</mat-icon>
    <span *ngIf="!isIrreversibleStatusChange"
      >You are changing status to {{ form.get('status')?.value?.name }}. It will deactivate associated items.</span
    >
    <span *ngIf="isIrreversibleStatusChange">
      <span>You are changing status to Inactive. It will deactivate associated items.</span>
      <span class="d-block">User will not be able to change the status to Active.</span>
    </span>
  </span>
</div>
<div class="modal-footer">
  <button
    appDisableButton
    (click)="update()"
    (keydown.enter)="update()"
    [disabled]="!canUpdate"
    color="accent"
    data-testid="change-status-modal-update-button"
    mat-flat-button
    type="button"
    >Update
  </button>
  <button
    appDisableButton
    (click)="updateAndEdit()"
    (keydown.enter)="updateAndEdit()"
    [disabled]="!canUpdateAndEdit"
    color="primary"
    data-testid="change-status-modal-update-and-edit-button"
    mat-flat-button
    type="button"
    >Update and go to edit</button
  >
  <button
    (click)="close()"
    color="primary"
    data-testid="change-status-modal-cancel-button"
    mat-flat-button
    type="button"
    >Cancel
  </button>
</div>
